import React, { useState, useEffect } from "react";
import {
  Statistic,
  Card,
  Row,
  Col,
  Typography,
  Skeleton,
} from "antd";
const App: React.FC = () => {
  const datestart = new Date('2024-08-26T01:28:00');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [remainTime, setRemainTime] = useState({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const updateCountdown = () => {
    const now = new Date();
    const start = datestart;
    const currentTimestamp = now.getTime();
    const startTimestamp = start.getTime();

    let distance = currentTimestamp - startTimestamp;

    if (distance < 0) {
      // 如果当前时间早于开始时间，则不更新时间
      return;
    }

    let years = now.getFullYear() - start.getFullYear();
    let months = now.getMonth() - start.getMonth();
    let days = now.getDate() - start.getDate();

    // 计算小时、分钟和秒
    const msInDay = 1000 * 60 * 60 * 24;
    const hours = Math.floor(distance % msInDay / (1000 * 60 * 60));
    const minutes = Math.floor((distance % msInDay % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % msInDay % (1000 * 60 * 60) % (1000 * 60)) / 1000);

    // 如果当前小时数小于开始时间的小时数，则减一天，并从月份和年份中调整
    if (hours < start.getHours()) {
      days = days - 1;
    }

    // 如果天数小于0，则从月份中借一天，并检查是否需要从年份中借一个月
    if (days < 0) {
      days += new Date(start.getFullYear(), start.getMonth() + 1, 0).getDate();
      months--;
    }

    // 如果月份小于0，则从年份中借一个月
    if (months < 0) {
      months += 12;
      years--;
    }

    // 更新剩余时间状态
    setRemainTime({
      years: years,
      months: months,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
  };

  useEffect(() => {
    updateCountdown();
    const timerId = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  

  return (
    <div
      className="App"
      style={{
        height: "100vh",
        background: "#f0f2f5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Card
        className="timer-card"
        style={{
          width: "80%",
          maxWidth: 600,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          padding: "2rem",
          textAlign: "center",
        }}
        hoverable
      >
        <Typography.Title level={4} style={{ marginBottom: "1rem" }}>
        Learn How to Love
        </Typography.Title>
        <Typography.Title
          level={5}
          style={{ marginBottom: "1rem", color: "#555" }}
        >
          Have Been
        </Typography.Title>
        {/* 使用 Skeleton 作为图片加载的占位符 */}
        {!imageLoaded && (
          <Skeleton.Image
            active
            className="feature-image"
            style={{ maxWidth: "100%", height: "auto", marginBottom: "2rem" }}
          />
        )}

        {/* 实际的图片标签，初始时不显示 */}
        <img
          src="https://oss.yzbh.tj.cn/gzq/_BL_0336.jpg"
          alt="Feature Image"
          style={{
            maxWidth: "100%",
            height: "auto",
            display: imageLoaded ? "block" : "none",
            marginBottom: "2rem",
          }}
          onLoad={() => setImageLoaded(true)} // 当图片加载完成时，更新状态
        />

        <Row
          gutter={16}
          align="middle"
          justify="center"
          style={{ marginTop: "2rem" }}
        >
          <Col span={12}>
            <Statistic
              title="Years"
              value={String(remainTime.years).padStart(2, "0")}
              style={{
                textAlign: "center",
                margin: "auto",
                fontSize: "1.2rem",
              }}
            />
            <Statistic
              title="Months"
              value={String(remainTime.months).padStart(2, "0")}
              style={{
                textAlign: "center",
                margin: "auto",
                fontSize: "1.2rem",
              }}
            />
            <Statistic
              title="Days"
              value={String(remainTime.days).padStart(2, "0")}
              style={{
                textAlign: "center",
                margin: "auto",
                fontSize: "1.2rem",
              }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Hours"
              value={String(remainTime.hours).padStart(2, "0")}
              style={{
                textAlign: "center",
                margin: "auto",
                fontSize: "1.2rem",
              }}
            />
            <Statistic
              title="Minutes"
              value={String(remainTime.minutes).padStart(2, "0")}
              style={{
                textAlign: "center",
                margin: "auto",
                fontSize: "1.2rem",
              }}
            />
            <Statistic
              title="Seconds"
              value={String(remainTime.seconds).padStart(2, "0")}
              style={{
                textAlign: "center",
                margin: "auto",
                fontSize: "1.2rem",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Text type="secondary">
              <i>Time flies, but love never fades.</i>
            </Typography.Text>
          </Col>
        </Row>
        <Row style={{ marginTop: "2rem" }}>
          <Col span={24}>
            <Typography.Text type="secondary">
              Beginning: <strong>{startTimeString()}</strong>
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text type="secondary">
              Present: <strong>{currentTimeString()}</strong>
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text type="secondary">
              Designed by{" "}
              <Typography.Link href="https://pyliubaolin.top/">
                Gardenia
              </Typography.Link>
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </div>
  );
  // Helper function to format start time string
  function startTimeString() {
    const start = datestart;
    return start.toLocaleString();
  }

  // Helper function to format current time string
  function currentTimeString() {
    const now = new Date();
    return now.toLocaleString();
  }
};

export default App;
